import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useStytch } from "@stytch/react";
import { StytchAPIError } from "@stytch/vanilla-js";
import axios from "axios";
import { Fragment, useCallback, useEffect, useState } from "react";
import { createUser } from "settings/api/Users";
import { Toast } from "shared/components/Toast";
import { tinykeys } from "tinykeys";
import TypeIt from "typeit-react";

export const SignUpPage: React.FC = () => {
  const { passwords } = useStytch();
  const [signupEmail, setSignupEmail] = useState<string | undefined>();
  const [signupPassword, setSignupPassword] = useState<string | undefined>();
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [weakPassword, setWeakPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openToast, setOpenToast] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleStytchError = (error: StytchAPIError) => {
    console.log(error.error_type);
    if (error.error_type === "duplicate_email") {
      setDuplicateEmail(true);
    } else if (error.error_type === "weak_password") {
      setWeakPassword(true);
    } else {
      setErrorMessage(error.error_message);
      setOpenToast(true);
    }
  };

  const createNewUser = useCallback(async () => {
    if (signupEmail && signupPassword && !loading) {
      try {
        setLoading(true);
        const result = await passwords.create({
          email: signupEmail,
          password: signupPassword,
          session_duration_minutes: 60 * 18,
        });

        axios.defaults.headers.common = {
          ...axios.defaults.headers.common,
          Authorization: result.session_jwt,
        };

        await createUser(result.user_id);

        window.location.replace("/thoughts");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err.error_type) {
          const stytchError = err as StytchAPIError;
          handleStytchError(stytchError);
        } else {
          setErrorMessage(err);
          setOpenToast(true);
        }
      } finally {
        setLoading(false);
      }
    }
  }, [loading, passwords, signupEmail, signupPassword]);

  const onEmailInput = (value: string) => {
    setSignupEmail(value);
    setDuplicateEmail(false);
  };

  const onPasswordInput = (value: string) => {
    setSignupPassword(value);
    setWeakPassword(false);
  };

  useEffect(() => {
    const unsubscribe = tinykeys(window, {
      Enter: () => {
        createNewUser();
      },
    });
    return () => {
      unsubscribe();
    };
  }, [createNewUser]);

  return (
    <Fragment>
      <Container>
        <Grid>
          <Typography variant="h3" align="center">
            Sign Up
          </Typography>
          <Grid size={12} style={{ margin: "0 auto", height: "60vh" }}>
            <FormControl fullWidth={true}>
              <FormLabel>Email</FormLabel>
              <TextField
                value={signupEmail}
                onChange={(e) => onEmailInput(e.target.value)}
                placeholder="Email"
                type="email"
              />
              <FormLabel error={duplicateEmail} hidden={!duplicateEmail}>
                Sorry, this email already exists
              </FormLabel>
            </FormControl>

            <FormControl fullWidth={true}>
              <FormLabel style={{ marginTop: "16px" }}>Password</FormLabel>
              <TextField
                value={signupPassword}
                onChange={(e) => onPasswordInput(e.target.value)}
                placeholder="Password"
                type="password"
              />
              <FormLabel error={weakPassword} hidden={!weakPassword}>
                Sorry, this password is too weak. You could try using a{" "}
                <Link
                  href="https://1password.com/password-generator"
                  color="inherit"
                  display={"inline"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography display={"inline"}>
                    password generator?
                  </Typography>
                </Link>
              </FormLabel>
            </FormControl>

            <FormControl fullWidth={true}>
              <Button
                variant="contained"
                onClick={createNewUser}
                style={{ marginTop: "24px" }}
              >
                {!loading ? (
                  "Sign Up"
                ) : (
                  <TypeIt
                    options={{
                      waitUntilVisible: true,
                      loop: true,
                    }}
                  >
                    Signing up...
                  </TypeIt>
                )}
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
      <Toast
        message={`Something went wrong:${errorMessage}`}
        open={openToast}
      />
    </Fragment>
  );
};
