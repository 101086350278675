import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import DOMPurify from "dompurify";
import { convertToHtml } from "shared/utils";
import { Thought } from "thought/types";
import { useTheme } from "@mui/material/styles";
import { ThoughtDiv } from "./ThoughtStyles.style";

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const formatThoughtTime = (given: Date): string => {
  return given.toLocaleTimeString("en-GB", dateOptions);
};

interface Props {
  thought: Thought;
}

export const ThoughtListItem: React.FC<Props> = ({ thought }) => {
  const theme = useTheme();

  return (
    <TimelineItem>
      <TimelineOppositeContent color="text.secondary">
        {thought.createdAt && formatThoughtTime(thought.createdAt)}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="secondary" />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <ThoughtDiv
          theme={theme}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(convertToHtml(thought.body)),
          }}
        />
      </TimelineContent>
    </TimelineItem>
  );
};
