import {
  Card,
  CardActionArea,
  CardContent,
  Grid2 as Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Thought } from "thought/types";
import { ThoughtDiv } from "./ThoughtStyles.style";
import { convertToHtml } from "shared/utils";
import DOMPurify from "dompurify";

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const formatThoughtTime = (given: Date): string => {
  return given.toLocaleTimeString("en-GB", dateOptions);
};

interface Props {
  thought: Thought;
}

export const ThoughtListMobile: React.FC<Props> = ({ thought }) => {
  const theme = useTheme();
  return (
    <Grid size={12}>
      <Grid>
        <Card variant="outlined" style={{ marginTop: "8px" }}>
          <CardActionArea style={{ minHeight: "100px" }}>
            <CardContent>
              <Typography
                variant="subtitle2"
                style={{ textDecoration: "underlined" }}
              >
                {thought.createdAt && formatThoughtTime(thought.createdAt)}
              </Typography>

              <Typography>
                <ThoughtDiv
                  theme={theme}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(convertToHtml(thought.body)),
                  }}
                />
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};
