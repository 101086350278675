import { Fragment } from "react/jsx-runtime";
import { Page } from "./Page";
import { ThoughtStreamProvider } from "./state/ThoughtStreamContext";

export const ThoughtPage: React.FC = () => {
  return (
    <Fragment>
      <ThoughtStreamProvider>
        <Page />
      </ThoughtStreamProvider>
    </Fragment>
  );
};
