import { Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { getCurrentUser, updateUser } from "./api/Users";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Toast } from "shared/components/Toast";
import { Loading } from "shared/components/Loading";
import TypeIt from "typeit-react";

export const SettingsPage: React.FC = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [successOpen, setSuccessOpen] = useState<boolean>(false);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onSave = async () => {
    setIsSaving(true);
    try {
      await updateUser(firstName, lastName);
      setSuccessOpen(true);
    } catch (err) {
      console.log(err);
      setErrorMessage(
        "Something went wrong while saving settings. Please try again.",
      );
      setErrorOpen(true);
    }
    setIsSaving(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const user = await getCurrentUser();
        if (user.name.first) {
          setFirstName(user.name.first);
        }
        if (user.name.last) {
          setLastName(user.name.last);
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setErrorMessage("Failed to load settings");
        setErrorOpen(true);
      }
    })();
  }, [setFirstName, setLastName, setIsLoading]);

  return (
    <Fragment>
      <div style={{ minHeight: "60vh" }}>
        {isLoading ? (
          <Loading>Loading settings...</Loading>
        ) : (
          <Grid container style={{ marginTop: "32px" }}>
            <Grid xs={12} item>
              <Typography
                variant="h3"
                style={{ marginBottom: "12px", textAlign: "center" }}
              >
                Settings
              </Typography>

              <Typography variant="h4" style={{ marginBottom: "8px" }}>
                User
              </Typography>

              <FormControl fullWidth={true}>
                <FormLabel>First Name</FormLabel>
                <TextField
                  value={firstName}
                  disabled={isSaving}
                  onChange={(event) => setFirstName(event.target.value)}
                ></TextField>
                <FormLabel style={{ marginTop: "8px" }}>Last Name</FormLabel>
                <TextField
                  value={lastName}
                  disabled={isSaving}
                  onChange={(event) => setLastName(event.target.value)}
                ></TextField>
                {isSaving ? (
                  <Button
                    variant="contained"
                    style={{ marginTop: "24px" }}
                    disabled={true}
                  >
                    <TypeIt options={{ loop: true }}>Saving...</TypeIt>
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{ marginTop: "24px" }}
                    onClick={onSave}
                  >
                    Save
                  </Button>
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Snackbar
          open={successOpen}
          autoHideDuration={1000}
          message="Settings saved"
          onClick={() => setSuccessOpen(false)}
          onClose={() => setSuccessOpen(false)}
        />
        <Toast
          open={errorOpen}
          message={errorMessage}
          onClose={() => setErrorOpen(false)}
        />
      </div>
    </Fragment>
  );
};
