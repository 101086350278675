import { useThoughts } from "../hooks/useThoughts";
import { Timeline, timelineOppositeContentClasses } from "@mui/lab";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid2 as Grid,
  useMediaQuery,
} from "@mui/material";
import { Thought } from "thought/types";
import { ThoughtListItem } from "./ThoughtListItem";
import { ThoughtStreamContext } from "thought/state/ThoughtStreamContext";
import { useContext } from "react";
import { ThoughtListMobile } from "./ThoughtListItemMobile";

export const ThoughtList: React.FC = () => {
  const { thoughts } = useThoughts();
  const { id } = useContext(ThoughtStreamContext);
  const onLargeScreen = useMediaQuery("(min-width:600px)");

  return (
    id &&
    (onLargeScreen ? (
      <Grid container style={{ marginTop: "32px" }}>
        <Grid size={12}>
          <Timeline
            position="right"
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0,
              },
            }}
          >
            {thoughts?.map((thought: Thought) => {
              return <ThoughtListItem key={thought.id} thought={thought} />;
            })}
          </Timeline>
        </Grid>
      </Grid>
    ) : (
      <Grid container style={{ marginTop: "32px" }}>
        {thoughts?.map((thought: Thought) => {
          return <ThoughtListMobile thought={thought} />;
        })}
      </Grid>
    ))
  );
};
