const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
};
export const formatDate = (given: Date): string => {
  return given.toLocaleDateString("en-GB", dateOptions);
};

export const formatTime = (given: Date): string => {
  return given.toLocaleTimeString("en-GB", timeOptions);
};
