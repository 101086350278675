import { Theme } from "@mui/material";
import styled from "styled-components";

export const ThoughtDiv = styled.div<{ theme: Theme }>`
  a {
    text-decoration: underline;
    color: inherit;
  }
  strong {
    font-weight: 1000;
  }
  code {
    background: ${(p) => p.theme.palette.primary.main}};
  }
`;
