import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: roboto;
    font-weight: normal;
    font-style: normal;
  }

  body{
    padding: 0%;
    margin: 0%;
    font-family: roboto;
    font-size: 16px;
  }
  #page {
      max-width: 1280px;
      margin: 0 auto;
      margin-top: 32px;
      padding: 32px;
  }

`;
