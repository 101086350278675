import { Grid2 as Grid, Link, Typography } from "@mui/material";
import { Fragment } from "react";

export const About: React.FC = () => {
  return (
    <Fragment>
      <Grid container>
        <Grid size={12}>
          <Typography
            variant="h3"
            style={{ marginBottom: "12px", textAlign: "center" }}
          >
            About
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: "32px" }}>
        <Grid size={12}>
          <Typography variant="body1">
            <p>
              <b>ThoughtStream.me</b> is a simple note taking app focused on
              getting raw thoughts onto "paper". I found myself writing notes as
              if I were speaking aloud, or in a "stream" in a notebook. However
              I found the notebook awkward and difficult to search for useful
              thoughts.
            </p>
            <p>
              So I decided to build this app as a place where I can "stream" my
              thoughts during meetings, while I'm working on something difficult
              or as a way to process something emotional. I hope you find it as
              useful as I have, and it helps bring some level of order to
              personal chaos. If you've got any suggestions, thoughts or ideas,
              let me know via{" "}
              <Link
                color="inherit"
                target="_blank"
                rel="noreferrer"
                href="https://mstdn.social/@hazz223"
              >
                Mastodon
              </Link>
              {" / "}
              <Link
                color="inherit"
                target="_blank"
                rel="noreferrer"
                href="https://bsky.app/profile/harrywinser.com"
              >
                Bluesky
              </Link>
              !
            </p>
            <p>Harry</p>
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: "32px" }}>
        <Grid size={12}>
          <Typography variant="h4">Privacy</Typography>
          <Typography variant="body1">
            <p>
              All notes are encrypted, protecting data in the event that bad
              actors get access to the database. However, titles/tags{" "}
              <strong>aren't</strong>, so I'd recommend not putting anything
              personal in these fields. I'll do my best to ensure that your
              content is secure. If you'd like your data removed or changed,
              please contact me via Mastodon, or via email
              <Link
                color="inherit"
                target="_blank"
                rel="noreferrer"
                href="https://www.harrywinser.com"
              >
                (which you can find on my website)
              </Link>
              . You can also read the Privacy Policy{" "}
              <Link color="inherit" href="/privacy-policy">
                here
              </Link>
              .
            </p>
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: "32px" }}>
        <Grid size={12}>
          <Typography variant="h4">Technology</Typography>
          <Typography variant="body1">
            <p>For those interested, this site is written using: </p>
            <ul>
              <li>Typescript</li>
              <li>Vite</li>
              <li>React</li>
            </ul>
            <p>The Backend is written using:</p>
            <ul>
              <li>AWS Lambda</li>
              <li>
                <Link
                  color="inherit"
                  target="_blank"
                  rel="noreferrer"
                  href="https://xata.io/"
                >
                  Xata
                </Link>{" "}
                for data storage
              </li>
            </ul>
            <p>Some of the 3rd party libraries I&#39;ve used are:</p>
            <ul>
              <li>
                <Link
                  color="inherit"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.typeitjs.com/"
                >
                  TypeIt
                </Link>
              </li>
              <li>
                <Link
                  color="inherit"
                  target="_blank"
                  rel="noreferrer"
                  href="https://mui.com/"
                >
                  MaterialUi
                </Link>
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};
