import { ErrorBoundary } from "react-error-boundary";
import { NavigationBar } from "./components";
import { ReactNode } from "react";
import { Typography } from "@mui/material";

import { Footer } from "home/Footer";

interface Props {
  children: ReactNode;
}

export const PageWrapper: React.FC<Props> = ({ children }) => {
  return (
    <div style={{ height: "100vh" }}>
      <ErrorBoundary fallback={<Typography>Something went wrong</Typography>}>
        <NavigationBar />
        <div id="page">{children}</div>
        <Footer />
      </ErrorBoundary>
    </div>
  );
};
