import { useContext } from "react";
import { ThoughtStreamContext } from "../state/ThoughtStreamContext";
import {
  createThoughts,
  addThought as addThoughtApi,
  updateThoughts,
} from "thought/api/thoughts";
import { Thought } from "thought/types";
import { orderTags } from "shared/utils";

export const useThoughts = () => {
  const context = useContext(ThoughtStreamContext);
  if (!context) {
    throw new Error("No thought context detected");
  }
  const { title, thoughts, setThoughts, setId, id, isLoading, tags, setTags } =
    context;

  const addThought = async (thought: Thought) => {
    if (id && thought.body) {
      const result = await addThoughtApi(id, thought);
      if (thoughts) {
        setThoughts([...thoughts, result]);
      } else {
        setThoughts([result]);
      }
    }
  };

  const createThoughtStream = async (thought: Thought) => {
    let populatedTitle = title;
    if (!populatedTitle) {
      populatedTitle = "New Thought";
    }
    const savedThoughts = await createThoughts(populatedTitle, thought);
    if (savedThoughts.id) {
      setId(savedThoughts.id);
      window.history.pushState(null, "", `/thoughts/${savedThoughts.id}`);
    }
    if (savedThoughts.thoughts) {
      setThoughts(savedThoughts.thoughts);
    }
  };

  const addTag = async (tag: string) => {
    if (id && tags) {
      const tagList = [...tags, tag];
      const orderedTags = orderTags(tagList);
      setTags(orderedTags);
      await updateThoughts(id, tagList);
    } else if (id) {
      setTags([tag]);
      await updateThoughts(id, [tag]);
    }
  };

  const deleteTag = async (tag: string) => {
    if (id && tags) {
      const filtered = tags.filter((x) => x !== tag);
      setTags(filtered);
      await updateThoughts(id, filtered);
    }
  };

  return {
    thoughts,
    addThought,
    createThoughtStream,
    isLoading,
    addTag,
    deleteTag,
  };
};
