import axios from "axios";
import { ThoughtStreamItem } from "../../types";
import { Page } from "thought/types";

export const getThoughtStreams = async (
  tags?: string[] | undefined,
  size = 10,
  page = 0,
): Promise<Page<ThoughtStreamItem[]>> => {
  let url = `/thoughts?page=${page}&size=${size}`;

  if (tags && tags.length > 0) {
    console.log(tags);
    const joinedTags = tags.join(",");
    url = `${url}&tags=${joinedTags}`;
  }

  const response = await axios.get<Page<ThoughtStreamItem[]>>(url);

  const converted = response.data.body.map((data) => {
    return {
      ...data,
      createdAt: new Date(data.createdAt),
    };
  });

  return {
    page: response.data.page,
    size: response.data.size,
    body: converted,
    hasNextPage: response.data.hasNextPage,
  };
};
