import axios from "axios";
import { CurrentUser } from "settings/types";

export const getCurrentUser = async (): Promise<CurrentUser> => {
  const response = await axios.get<CurrentUser>("/users/current");
  return response.data;
};

export const createUser = async (stytchUserId: string) => {
  const body = { stytchId: stytchUserId };

  const response = await axios.put("/users", body);

  if (response.status === 200 || response.status === 201) {
    console.log("user logged in");
  } else {
    console.log(JSON.stringify(response));
    throw new Error("Failed to create user");
  }
};

export const updateUser = async (firstName: string, lastName: string) => {
  await axios.patch("/users", {
    name: {
      first: firstName,
      last: lastName,
    },
  });
};
