import axios from "axios";
import { Thought, Thoughts } from "../types.ts";

const convertThought = (original: Thought): Thought => {
  const createdAt = original.createdAt && new Date(original.createdAt);
  const updatedAt = original.updatedAt && new Date(original.updatedAt);
  return { ...original, createdAt, updatedAt };
};

export const updateThoughts = async (
  id: string,
  tags: string[],
): Promise<void> => {
  await axios.patch<void>("/thoughts", {
    id,
    tags,
  });
};

export const createThoughts = async (
  title: string,
  thought: Thought,
): Promise<Thoughts> => {
  const response = await axios.put<Thoughts>("/thoughts", {
    title,
    thoughts: [thought],
  });

  const data = response.data;
  const thoughts = data.thoughts?.map((item) => convertThought(item));
  const createdAt = data.createdAt && new Date(data.createdAt);
  const updatedAt = data.updatedAt && new Date(data.updatedAt);
  return {
    ...data,
    thoughts,
    createdAt,
    updatedAt,
  };
};

export const addThought = async (
  thoughtsId: string,
  thought: Thought,
): Promise<Thought> => {
  const response = await axios.put<Thought>(`/thoughts/${thoughtsId}`, thought);

  return convertThought(response.data);
};

export const getThoughts = async (thoughtsId: string): Promise<Thoughts> => {
  const response = await axios.get<Thoughts>(`/thoughts/${thoughtsId}`);

  const data = response.data;
  const thoughts = data.thoughts?.map((item) => convertThought(item));
  const createdAt = data.createdAt && new Date(data.createdAt);
  const updatedAt = data.updatedAt && new Date(data.updatedAt);

  return {
    ...data,
    thoughts,
    createdAt,
    updatedAt,
  };
};
