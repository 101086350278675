import { isAxiosError } from "axios";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getThoughts } from "thought/api/thoughts";
import { Thought } from "thought/types";

interface ThoughtsContextInterface {
  id: undefined | string;
  setId: (id: string) => void;
  thoughts: undefined | Thought[];
  setThoughts: (thoughts: Thought[]) => void;
  title: undefined | string;
  setTitle: (id: string) => void;
  isLoading: boolean;
  tags: undefined | string[];
  setTags: (tags: string[]) => void;
}

export const ThoughtStreamContext = createContext<ThoughtsContextInterface>({
  id: undefined,
  thoughts: [],
  setThoughts: () => undefined,
  setId: () => undefined,
  title: undefined,
  setTitle: () => undefined,
  isLoading: true,
  tags: [],
  setTags: () => undefined,
});

const orderTags = (tags: string[]) => {
  return tags.sort((a, b) => a.localeCompare(b));
};

export const ThoughtStreamProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { thoughtsId } = useParams();
  const [thoughts, setThoughts] = useState<Thought[]>();
  const [id, setId] = useState<string | undefined>(thoughtsId);
  const [title, setTitle] = useState<string | undefined>();
  const { thoughtId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      if (thoughtId && thoughtId !== "new") {
        setIsLoading(true);
        try {
          const foundThoughts = await getThoughts(thoughtId);
          setTitle(foundThoughts.title);
          setThoughts(foundThoughts.thoughts);
          setId(thoughtId);
          setTags(foundThoughts.tags ? orderTags(foundThoughts.tags) : []);
          setIsLoading(false);
        } catch (err) {
          if (isAxiosError(err)) {
            if (err.status === 401) {
              window.location.replace("/not-authenticated");
            } else {
              console.log(err);
            }
          }
        }
      } else {
        setTitle("");
        setThoughts([]);
        setId(undefined);
        setIsLoading(false);
        setTags([]);
      }
    })();
  }, [thoughtId, setId, setThoughts, setTitle, setIsLoading, setTags]);

  return (
    <ThoughtStreamContext.Provider
      value={{
        thoughts,
        setThoughts,
        setId,
        id,
        title,
        setTitle,
        isLoading,
        tags,
        setTags,
      }}
    >
      {children}
    </ThoughtStreamContext.Provider>
  );
};
