import { Container, Grid2 as Grid, Typography, useTheme } from "@mui/material";
import { useCallback, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ThoughtStreamContext } from "thought/state/ThoughtStreamContext";

export const ThoughtHeader: React.FC = () => {
  const [params] = useSearchParams();
  const { title, setTitle, id } = useContext(ThoughtStreamContext);

  const setTitleFromQueryParams = useCallback(() => {
    if (!id) {
      if (params.get("title")) {
        setTitle(params.get("title") as string);
      } else {
        setTitle("");
      }
    }
  }, [params, setTitle, id]);

  useEffect(() => {
    setTitleFromQueryParams();
  }, [setTitleFromQueryParams]);

  const theme = useTheme();
  return (
    <Container>
      <Grid container>
        <Grid size={12}>
          {id ? (
            <Typography
              variant="h3"
              style={{
                textAlign: "center",
                height: "120px",
                border: "none",
              }}
            >
              {title}
            </Typography>
          ) : (
            <input
              style={{
                height: "80px",
                fontSize: "3.75rem",
                border: "none",
                width: "100%",
                outlineWidth: "0px",
                outline: "none",
                fontFamily: theme.typography.fontFamily,
                background: "none",
                textAlign: "center",
              }}
              disabled={!!id}
              placeholder="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            ></input>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
