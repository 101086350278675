import {
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { formatDate, orderTags } from "shared/utils";
import { ThoughtStreamItem } from "types";

interface Props {
  item: ThoughtStreamItem;
}

export const ThoughtStreamCard: React.FC<Props> = ({ item }) => {
  return (
    <Grid
      container
      style={{
        marginTop: "32px",
        justifyContent: "center",
      }}
    >
      <Grid size={12}>
        <Link
          to={`/thoughts/${item.id}`}
          style={{ color: "black", textDecoration: "none" }}
        >
          <Card variant="outlined">
            <CardActionArea style={{ minHeight: "120px" }}>
              <CardContent>
                <Typography>{formatDate(item.createdAt)}</Typography>
                <Typography variant="h5" component="div">
                  {item.title}
                </Typography>
                {item.tags &&
                  orderTags(item.tags).map((tag) => {
                    return (
                      <Chip
                        style={{ marginTop: "8px", marginRight: "8px" }}
                        key={tag}
                        label={tag}
                      />
                    );
                  })}
              </CardContent>
            </CardActionArea>
          </Card>
        </Link>
      </Grid>
    </Grid>
  );
};
