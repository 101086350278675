import {
  Grid2 as Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Fragment } from "react";
import desktopExample from "../assests/desktop-example.png";
import newThought from "../assests/previous-thoughts.png";

export const Home: React.FC = () => {
  const theme = useTheme();
  const onLargeScreen = useMediaQuery("(min-width:600px)");

  return (
    <Fragment>
      <Grid container spacing={10}>
        <Grid size={{ xs: 12 }} style={{ textAlign: "center" }}>
          {onLargeScreen ? (
            <Typography variant="h2">ThoughtStream.me</Typography>
          ) : (
            <Typography variant="h4">ThoughtStream.me</Typography>
          )}
        </Grid>
        <Grid size={{ xs: 12, sm: 5 }}>
          {onLargeScreen ? (
            <Typography variant="h3">A different way of note taking</Typography>
          ) : (
            <Typography variant="h5">A different way of note taking</Typography>
          )}
          <Typography variant="body1">
            <p>
              <b>ThoughtStream.me</b> is a simple note taking app focused on
              getting raw thoughts onto "paper". <b>ThoughtStream.me</b> is a
              great app to help take notes during a meeting, keeping your
              thoughts organised throughout the day, or for keeping a nightly
              diary.
            </p>
            <p>
              Whatever you use it for, we hope you get something valuable out of
              it.
            </p>
          </Typography>
        </Grid>
        <Grid
          size={{ xs: 12, sm: 7 }}
          style={{
            border: "solid",
            boxShadow: `10px 10px 10px ${theme.palette.primary.main}`,
            borderColor: theme.palette.primary.main,
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              overflow: "hidden",
            }}
            src={desktopExample}
            alt="Example of the app on a macOS desktop"
          />
        </Grid>
        <Grid
          size={{ xs: 12, sm: 7 }}
          style={{
            border: "solid",
            boxShadow: `10px 10px 10px ${theme.palette.primary.main}`,
            borderColor: theme.palette.primary.main,
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              overflow: "hidden",
            }}
            src={newThought}
            alt="Example of the app on a macOS desktop"
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 5 }}>
          {onLargeScreen ? (
            <Typography variant="h3">What is a "Thought Stream"?</Typography>
          ) : (
            <Typography variant="h5">What is a "Thought Stream"?</Typography>
          )}
          <Typography variant="body1">
            <p>
              With <b>ThoughtStream.me</b>, you take notes like you would write
              a thread on Bluesky, Mastodon, or other microblogging sites. We
              see these as "thought streams". Small, individual thoughts caught
              and displayed in a timeline.
            </p>
            <p>
              These streams are always there for you to add to, and read though.
              You can also tag "Thoughts", to make them easier to find and
              collect. There's also a "Daily Thought" button, to quickly start a
              thought stream for the day.
            </p>
            <p>
              More features will be coming soon, like exports, linking between
              thought streams, and more customisation options.
            </p>
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};
