import { Fragment } from "react/jsx-runtime";
import { Page } from "./Page";
import { ThoughtsContextProvider } from "./context/ThoughtsContext";

export const ThoughtsPage: React.FC = () => {
  return (
    <Fragment>
      <ThoughtsContextProvider>
        <Page />
      </ThoughtsContextProvider>
    </Fragment>
  );
};
