import { useStytch, useStytchUser } from "@stytch/react";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { createUser } from "settings/api/Users";
import { Loading } from "shared/components/Loading";
import { Toast } from "shared/components/Toast";

const Authentication = () => {
  const stytch = useStytch();
  const { user } = useStytchUser();
  const [openToast, setOpenToast] = useState(false);

  useEffect(() => {
    (async () => {
      if (stytch && !user) {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get("token");
        const tokenType = queryParams.get("stytch_token_type");

        if (token && tokenType) {
          if (tokenType === "magic_links") {
            const result = await stytch.magicLinks.authenticate(token, {
              session_duration_minutes: 60,
            });

            axios.defaults.headers.common = {
              ...axios.defaults.headers.common,
              Authorization: result.session_jwt,
            };

            try {
              await createUser(result.user_id);
            } catch (err) {
              console.log(err);
              setOpenToast(true);
            }

            window.location.replace("/thoughts");
          }
        }
      } else {
        window.location.replace("/thoughts");
      }
    })();
  }, [stytch, user, setOpenToast]);

  return (
    <Fragment>
      <Loading>Authenticating...</Loading>
      <Toast
        message="Something went wrong while authenticating! Please contact us."
        open={openToast}
      />
    </Fragment>
  );
};

export default Authentication;
