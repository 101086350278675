import { Fragment } from "react/jsx-runtime";
import { Loading } from "shared/components/Loading";
import { Button, Container, Grid2 as Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ThoughtsSearch } from "./components/ThoughtsSearch";
import { useThoughts } from "./context/ThoughtsContext";
import { Pagination } from "shared/components/pagination/Pagination";
import { ThoughtStreamCard } from "./components/ThoughtStreamCard";

export const Page: React.FC = () => {
  const { isLoading, thoughts, isSearching } = useThoughts();

  return (
    <Container style={{ minHeight: "60vh" }}>
      {!isLoading && (
        <Fragment>
          <Typography variant="h2" align="center">
            Previous thoughts
          </Typography>
          <ThoughtsSearch />
        </Fragment>
      )}
      {isLoading || isSearching ? (
        <div>
          {isLoading ? (
            <Loading>Loading thoughts...</Loading>
          ) : (
            <Loading>Searching...</Loading>
          )}
        </div>
      ) : thoughts && thoughts.length > 0 ? (
        <Grid>
          {thoughts.map((item) => {
            return <ThoughtStreamCard key={item.id} item={item} />;
          })}
          <Pagination />
        </Grid>
      ) : (
        <Fragment>
          <Grid marginTop={10} textAlign={"center"}>
            <Typography>
              No previous thoughts recorded. Why don't you create a new one?
            </Typography>
          </Grid>
          <Grid marginTop={10} textAlign={"center"}>
            <Link to={"/thoughts/new"}>
              <Button variant="contained">
                <Typography>New thought</Typography>
              </Button>
            </Link>
          </Grid>
        </Fragment>
      )}
    </Container>
  );
};
