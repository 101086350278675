import { Alert, Snackbar, Typography } from "@mui/material";
import { Fragment } from "react/jsx-runtime";

interface Props {
  message: string;
  open: boolean;
  onClose?: () => void;
}

export const Toast: React.FC<Props> = ({ message, open, onClose }) => {
  return (
    <Fragment>
      <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
        <Alert onClose={onClose} severity="error" variant="filled">
          <Typography>{message}</Typography>
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
