import {
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useStytch } from "@stytch/react";
import { StytchAPIError } from "@stytch/vanilla-js";
import axios from "axios";
import { Fragment, useCallback, useEffect, useState } from "react";
import { createUser } from "settings/api/Users";
import { Toast } from "shared/components/Toast";
import { tinykeys } from "tinykeys";
import TypeIt from "typeit-react";

export const LoginPage = () => {
  const { passwords } = useStytch();
  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [emailUnknown, setEmailUnknown] = useState(false);
  const [passwordUnknown, setPasswordUnknown] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openToast, setOpenToast] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleStytchError = (error: StytchAPIError) => {
    /// In here, we need to handle differnet knids of errors, including when a stych email coudln't be found
    console.log(error.error_type);
    if (error.error_type === "email_not_found") {
      setEmailUnknown(true);
    } else if (error.error_type === "unauthorized_credentials") {
      setPasswordUnknown(true);
    } else {
      setErrorMessage(error.error_message);
      setOpenToast(true);
    }
  };

  const loginViaEmail = useCallback(async () => {
    if (email && password && !loading) {
      setLoading(true);
      try {
        const result = await passwords.authenticate({
          email: email,
          password: password,
          session_duration_minutes: 60 * 18, // 18 hours login,
        });
        axios.defaults.headers.common = {
          ...axios.defaults.headers.common,
          Authorization: result.session_jwt,
        };
        await createUser(result.user_id);
        window.location.replace("/thoughts");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err.error_type) {
          const stytchError = err as StytchAPIError;
          handleStytchError(stytchError);
        } else {
          setErrorMessage(err);
          setOpenToast(true);
        }
      } finally {
        setLoading(false);
      }
    }
  }, [email, loading, password, passwords]);

  const onEmailInput = (value: string) => {
    setEmail(value);
    setEmailUnknown(false);
  };

  const onPasswordInput = (value: string) => {
    setPassword(value);
    setPasswordUnknown(false);
  };

  useEffect(() => {
    const unsubscribe = tinykeys(window, {
      Enter: () => {
        loginViaEmail();
      },
    });
    return () => {
      unsubscribe();
    };
  }, [loginViaEmail]);

  return (
    <Fragment>
      <Container>
        <Grid>
          <Typography variant="h3" align="center">
            Login
          </Typography>
        </Grid>
        <Grid size={12} style={{ margin: "0 auto", height: "60vh" }}>
          <FormControl fullWidth={true}>
            <FormLabel>Email</FormLabel>
            <TextField
              value={email}
              onChange={(e) => onEmailInput(e.target.value)}
              placeholder="Email"
              type="email"
            />
          </FormControl>
          <FormLabel error={emailUnknown} hidden={!emailUnknown}>
            Sorry, we can't find that email address.
          </FormLabel>

          <FormControl fullWidth={true}>
            <FormLabel style={{ marginTop: "16px" }}>Password</FormLabel>
            <TextField
              value={password}
              onChange={(e) => onPasswordInput(e.target.value)}
              placeholder="Password"
              type="password"
              error={passwordUnknown}
            />
          </FormControl>
          <FormLabel error={passwordUnknown} hidden={!passwordUnknown}>
            Incorrect password; please try again.
          </FormLabel>

          <FormControl fullWidth={true}>
            <Button
              variant="contained"
              onClick={loginViaEmail}
              style={{ marginTop: "24px" }}
              disabled={loading}
              type="submit"
            >
              {!loading ? (
                "Login"
              ) : (
                <TypeIt
                  options={{
                    waitUntilVisible: true,
                    loop: true,
                  }}
                >
                  Logging in...
                </TypeIt>
              )}
            </Button>
          </FormControl>

          <Divider style={{ marginTop: "24px", marginBottom: "24px" }}>
            <Typography>Or</Typography>
          </Divider>
          <FormControl fullWidth>
            <Link href="/signup" color="inherit" alignSelf="center">
              <Typography>Signup</Typography>
            </Link>
          </FormControl>
        </Grid>
      </Container>

      <Toast
        message={`Something went wrong:${errorMessage}`}
        open={openToast}
      />
    </Fragment>
  );
};
