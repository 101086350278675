import { Typography } from "@mui/material";
import { ReactNode } from "react";
import styled from "styled-components";
import TypeIt from "typeit-react";

const LoadingContent = styled.div`
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  children: ReactNode;
}

export const Loading: React.FC<Props> = ({ children }) => {
  return (
    <Typography variant="subtitle1">
      <LoadingContent>
        <TypeIt
          options={{
            speed: 20,
            waitUntilVisible: true,
          }}
        >
          {children}
        </TypeIt>
      </LoadingContent>
    </Typography>
  );
};
