import { Converter } from "showdown";
const converter = new Converter({
  simplifiedAutoLink: true,
  strikethrough: true,
  ghCodeBlocks: true,
});

export const convertToHtml = (given: string): string => {
  const converted = converter.makeHtml(given);
  return converted;
};
