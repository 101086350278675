import { Typography } from "@mui/material";
import styled from "styled-components";

const ErrorBox = styled.div`
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotFoundPage: React.FC = () => {
  return (
    <ErrorBox>
      <Typography>Sorry, this page couldn't be found.</Typography>
    </ErrorBox>
  );
};
