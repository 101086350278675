import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useStytch, useStytchUser } from "@stytch/react";
import { Notes } from "@mui/icons-material";

const ListItemLink = styled(Link)`
  color: black;
  text-decoration: none;
  display: inline;
  width: 100%;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

const getDailyTitle = (): string => {
  const dateString = new Date().toLocaleDateString("en-GB", dateOptions);

  return `${dateString} 📋`;
};

export const Draw: React.FC<Props> = ({ isOpen, onClose }) => {
  const user = useStytchUser();
  const stytchClient = useStytch();
  const theme = useTheme();

  const onLogout = async () => {
    console.log(user);
    await stytchClient.session.revoke();
    window.location.replace("/");
  };

  const DrawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={onClose}
      // Todo: look and see if i can rearrange this!
      style={{ background: theme.palette.secondary.main, height: "100%" }}
    >
      <List>
        <ListItem>
          <ListItemLink to={"/thoughts/new"}>
            <ListItemButton>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="New thought" />
            </ListItemButton>
          </ListItemLink>
        </ListItem>
        <ListItem>
          <ListItemLink to={`/thoughts/new?title=${getDailyTitle()}`}>
            <ListItemButton>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Daily thought" />
            </ListItemButton>
          </ListItemLink>
        </ListItem>
        <ListItem>
          <ListItemLink to={"/thoughts"}>
            <ListItemButton>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Thoughts" />
            </ListItemButton>
          </ListItemLink>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemLink to={"/settings"}>
            <ListItemButton>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItemLink>
        </ListItem>
        <ListItem>
          <ListItemLink to={"/about"}>
            <ListItemButton>
              <ListItemIcon>
                <Notes />
              </ListItemIcon>
              <ListItemText primary="About" />
            </ListItemButton>
          </ListItemLink>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" onClick={onLogout} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    user.user && (
      <Drawer open={isOpen} onClick={onClose} variant="temporary">
        {DrawerList}
      </Drawer>
    )
  );
};
