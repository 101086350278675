import { Button, Grid2 as Grid, useMediaQuery } from "@mui/material";
import { Fragment, useCallback } from "react";
import { useThoughts } from "thoughts/context/ThoughtsContext";

export const Pagination: React.FC = () => {
  const { page, setPage, isSearching, hasMore } = useThoughts();
  const onLargeScreen = useMediaQuery("(min-width:600px)");

  const handleNextPage = useCallback(async () => {
    if (!isSearching) {
      const newPage = page + 1;
      setPage(newPage);
    }
  }, [page, setPage, isSearching]);

  const handlePreviousPage = useCallback(async () => {
    if (!isSearching && page > 0) {
      const newPage = page - 1;
      setPage(newPage);
    }
  }, [page, setPage, isSearching]);

  return (
    <Grid container style={{ marginTop: "32px", justifyContent: "center" }}>
      {onLargeScreen ? (
        <Fragment>
          <Grid size={6}>
            <Button
              variant="contained"
              onClick={handlePreviousPage}
              disabled={page === 0}
              style={{ minWidth: "200px" }}
            >
              Previous
            </Button>
          </Grid>
          <Grid
            size={6}
            container
            alignSelf={"end"}
            alignContent={"end"}
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              onClick={handleNextPage}
              disabled={!hasMore}
              style={{ minWidth: "200px" }}
            >
              Next
            </Button>
          </Grid>
        </Fragment>
      ) : (
        <Fragment>
          <Grid size={12}>
            <Button
              variant="contained"
              onClick={handleNextPage}
              disabled={!hasMore}
              fullWidth
            >
              Next
            </Button>
          </Grid>
          <Grid size={12} style={{ marginTop: "16px" }}>
            <Button
              variant="contained"
              onClick={handlePreviousPage}
              disabled={page === 0}
              fullWidth
            >
              Previous
            </Button>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};
