import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { GlobalStyle } from "./GlobalStyle.ts";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";
import Authentication from "./auth/Authentication.tsx";
import { LoginPage } from "./auth/LoginPage.tsx";
import { Home } from "./home/Home.tsx";
import { ThoughtPage } from "./thought/ThoughtPage.tsx";
import { ThoughtsPage } from "./thoughts/ThoughtsPage.tsx";
import axios from "axios";
import Cookies from "js-cookie";
import { SettingsPage } from "settings/SettingsPage.tsx";
import { PageWrapper } from "shared/PageWrapper.tsx";
import { NotFoundPage } from "error/NotFoundPage.tsx";
import { NotAuthenticatedPage } from "error/NotAuthenticated.tsx";
import { grey } from "@mui/material/colors";
import { About } from "about/About.tsx";
import { SignUpPage } from "auth/SignUpPage.tsx";
import { PrivacyPage } from "privacy/PrivacyPage.tsx";

axios.defaults.baseURL = "https://api.thoughtstream.me";

if (Cookies.get("stytch_session_jwt")) {
  axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    Authorization: `${Cookies.get("stytch_session_jwt")}`,
  };
}

const stytch = new StytchUIClient(import.meta.env.VITE_STYTCH);
const router = createBrowserRouter([
  {
    path: "*",
    element: (
      <PageWrapper>
        <NotFoundPage />
      </PageWrapper>
    ),
  },
  {
    path: "/",
    element: (
      <PageWrapper>
        <Home />
      </PageWrapper>
    ),
  },
  {
    path: "/about",
    element: (
      <PageWrapper>
        <About />
      </PageWrapper>
    ),
  },
  {
    path: "/authenticate",
    element: (
      <PageWrapper>
        <Authentication />
      </PageWrapper>
    ),
  },
  {
    path: "/settings",
    element: (
      <PageWrapper>
        <SettingsPage />
      </PageWrapper>
    ),
  },
  {
    path: "/thoughts/:thoughtId",
    element: (
      <PageWrapper>
        <ThoughtPage />
      </PageWrapper>
    ),
  },
  {
    path: "/thoughts",
    element: (
      <PageWrapper>
        <ThoughtsPage />
      </PageWrapper>
    ),
  },
  {
    path: "/login",
    element: (
      <PageWrapper>
        <LoginPage />
      </PageWrapper>
    ),
  },
  {
    path: "/signup",
    element: (
      <PageWrapper>
        <SignUpPage />
      </PageWrapper>
    ),
  },
  {
    path: "/not-authenticated",
    element: (
      <PageWrapper>
        <NotAuthenticatedPage />
      </PageWrapper>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <PageWrapper>
        <PrivacyPage />
      </PageWrapper>
    ),
  },
]);

const theme = createTheme({
  palette: {
    primary: {
      main: grey["A100"],
    },
    secondary: {
      main: grey["A200"],
    },
    text: {
      primary: "#000",
      secondary: "#000",
    },
  },
  typography: {
    fontFamily: ["Courier Prime"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: grey["A100"],
          "&:hover": {
            background: grey["A200"],
          },
          ":disabled": {
            background: grey["A200"],
          },
        },
      },
    },
  },
});

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <GlobalStyle />
      <StytchProvider stytch={stytch}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </StytchProvider>
    </StyledEngineProvider>
  </StrictMode>,
);
