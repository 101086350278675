import { Chip, Grid2 as Grid, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { useThoughts } from "thought/hooks/useThoughts";
import { ThoughtStreamContext } from "thought/state/ThoughtStreamContext";

export const ThoughtTags: React.FC = () => {
  const { id, tags } = useContext(ThoughtStreamContext);
  const { addTag, deleteTag } = useThoughts();
  const [tagValue, setTagValue] = useState<string>();

  const onNewTag = () => {
    if (tagValue) {
      addTag(tagValue);
      setTagValue("");
    }
  };

  const handleTagDelete = (tag: string) => {
    deleteTag(tag);
  };

  const handleKey = (key: string): boolean => {
    if (key === " " || key === "Enter") {
      onNewTag();
      return true;
    }
    return false;
  };

  return (
    id && (
      <Grid
        container
        style={{
          marginTop: "32px",
        }}
      >
        <Grid size={{ xs: 12, sm: 2 }}>
          <TextField
            id="margin-dense"
            value={tagValue}
            size="small"
            onChange={(e) => setTagValue(e.target.value)}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            onKeyDown={(ev) => {
              if (handleKey(ev.key)) {
                ev.preventDefault();
              }
            }}
            placeholder="Add a tag"
            style={{ marginTop: "12px" }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 10 }}>
          {tags?.map((tag) => {
            return (
              <Chip
                style={{ marginTop: "8px", marginRight: "8px" }}
                key={tag}
                label={tag}
                onDelete={() => handleTagDelete(tag)}
              />
            );
          })}
        </Grid>
      </Grid>
    )
  );
};
