import { Chip, Grid2 as Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useThoughts } from "thoughts/context/ThoughtsContext";

export const ThoughtsSearch: React.FC = () => {
  const [tagValue, setTagValue] = useState<string>();
  const { tags, setTags } = useThoughts();

  const onNewTag = async () => {
    if (tagValue) {
      setTags([...tags, tagValue]);
      setTagValue("");
    }
  };

  const handleTagDelete = async (tag: string) => {
    const otherTags = tags.filter((item) => item !== tag);
    setTags(otherTags);
  };

  const handleKey = (key: string): boolean => {
    if (key === " " || key === "Enter") {
      onNewTag();
      return true;
    }
    return false;
  };

  return (
    <Grid container style={{ marginTop: "32px", justifyContent: "center" }}>
      <Grid size={{ xs: 12, sm: 3 }}>
        <TextField
          id="margin-dense"
          value={tagValue}
          size="small"
          onChange={(e) => setTagValue(e.target.value)}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          onKeyDown={(ev) => {
            if (handleKey(ev.key)) {
              ev.preventDefault();
            }
          }}
          placeholder="Filter by tag"
          style={{ marginTop: "12px" }}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 9 }}>
        {tags?.map((tag) => {
          return (
            <Chip
              style={{ marginTop: "8px", marginRight: "8px" }}
              key={tag}
              label={tag}
              onDelete={() => handleTagDelete(tag)}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};
