import { isAxiosError } from "axios";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { getThoughtStreams } from "thoughts/api";
import { ThoughtStreamItem } from "types";

interface ThoughtsContextInterface {
  thoughts: ThoughtStreamItem[] | undefined;
  isLoading: boolean;
  loadThoughts: (size: number, page: number, tags?: string[]) => Promise<void>;
  page: number;
  setPage: (page: number) => void;
  size: number;
  setSize: (size: number) => void;
  tags: string[];
  setTags: (tags: string[]) => void;
  isSearching: boolean;
  hasMore: boolean;
}

export const ThoughtsContext = createContext<ThoughtsContextInterface>({
  thoughts: undefined,
  isLoading: true,
  loadThoughts: () => Promise.any([]),
  page: 0,
  setPage: () => undefined,
  size: 0,
  setSize: () => undefined,
  tags: [],
  setTags: () => undefined,
  isSearching: false,
  hasMore: false,
});

export const ThoughtsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [params] = useSearchParams();
  const paramSize = params.get("size");
  const urlSize = paramSize && parseInt(paramSize);
  const paramPage = params.get("page");
  const urlPage = paramPage && parseInt(paramPage);

  const [thoughts, setThoughts] = useState<ThoughtStreamItem[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [page, setPage] = useState(urlPage ? urlPage : 0);
  const [size, setSize] = useState(urlSize ? urlSize : 10);
  const [hasMore, setHasMore] = useState(false);
  const [tags, setTags] = useState<string[]>([]);

  const loadThoughts = async (
    size = 10,
    page = 0,
    tags: string[] | undefined = undefined,
  ) => {
    try {
      setIsSearching(true);

      const thoughtSteamsPage = await getThoughtStreams(tags, size, page);
      setThoughts(thoughtSteamsPage.body);
      setHasMore(thoughtSteamsPage.hasNextPage);
      setIsSearching(false);
      setIsLoading(false);

      const params = new URLSearchParams(location.search);
      params.set("page", `${page}`);
      window.history.replaceState({}, "", `${location.pathname}?${params}`);
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.status === 401) {
          window.location.replace("/not-authenticated");
        }
      } else {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    loadThoughts(size, page, tags);
  }, [page, size, tags]);

  return (
    <ThoughtsContext.Provider
      value={{
        thoughts,
        isLoading,
        loadThoughts,
        isSearching,
        page,
        setPage,
        size,
        setSize,
        hasMore,
        tags,
        setTags,
      }}
    >
      {children}
    </ThoughtsContext.Provider>
  );
};

export const useThoughts = () => {
  const context = useContext(ThoughtsContext);

  if (!context) {
    throw Error("Cannot use useThoughts outside of a ThoughtsContext");
  }

  return { ...context };
};
