import {
  Button,
  Grid2 as Grid,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
  Fragment,
} from "react";
import { ThoughtStreamContext } from "thought/state/ThoughtStreamContext";
import { Toast } from "shared/components/Toast";
import { isAxiosError } from "axios";
import { useThoughts } from "thought/hooks/useThoughts";
import { tinykeys } from "tinykeys";
import TypeIt from "typeit-react";

export const ThoughtAction: React.FC = () => {
  const { id } = useContext(ThoughtStreamContext);
  const [successOpen, setSuccessOpen] = useState<boolean>(false);
  const [currentThought, setCurrentThought] = useState<string>("");
  const { createThoughtStream, addThought } = useThoughts();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const onLargeScreen = useMediaQuery("(min-width:600px)");

  const setFocus = useCallback(() => {
    if (inputRef && inputRef.current && !submitting) {
      inputRef.current.focus();
    }
  }, [inputRef, submitting]);

  //todo  move this to a hook.
  const onThoughtSubmit = useCallback(async () => {
    if (!currentThought) {
      return;
    }

    setSubmitting(true);

    try {
      if (id) {
        await addThought({ body: currentThought });
      } else {
        await createThoughtStream({ body: currentThought });
      }
      setSuccessOpen(true);
      setCurrentThought("");
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.status === 401) {
          window.location.replace("/not-authenticated");
        }
      }
      console.log(err);
      setErrored(true);
    } finally {
      setSubmitting(false);
      setFocus();
    }
  }, [currentThought, addThought, createThoughtStream, id, setFocus]);

  const addBold = useCallback(() => {
    setCurrentThought(currentThought + "****");
  }, [currentThought, setCurrentThought]);

  const addItalic = useCallback(() => {
    setCurrentThought(currentThought + "__");
  }, [currentThought, setCurrentThought]);

  const addLink = useCallback(() => {
    setCurrentThought(currentThought + "[]()");
  }, [currentThought, setCurrentThought]);

  const onPost = useCallback(async () => {
    await onThoughtSubmit();
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }

    window.scrollTo(0, document.body.scrollHeight + 200);
  }, [inputRef, onThoughtSubmit]);

  useEffect(() => {
    const unsubscribe = tinykeys(window, {
      "Meta+B": () => {
        addBold();
      },
      "Meta+I": () => {
        addItalic();
      },
      "Meta+K": (event) => {
        event.preventDefault();
        addLink();
      },
      "Meta+Enter": () => {
        onPost();
      },
    });
    return () => {
      unsubscribe();
    };
  }, [addBold, addItalic, addLink, onPost]);

  return (
    <Fragment>
      <Grid
        container
        style={{
          marginTop: "32px",
        }}
      >
        <Grid size={12}>
          <TextField
            id="thought-input"
            fullWidth={true}
            multiline
            rows={4}
            placeholder="Whats on your mind?"
            variant="standard"
            value={currentThought}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (!submitting) {
                setCurrentThought(event.target.value);
              }
            }}
            InputProps={{
              disableUnderline: true,
            }}
            inputRef={inputRef}
            autoFocus
          />
        </Grid>
        <Grid container size={12}>
          <Grid size={{ xs: 12, sm: 3 }}>
            {onLargeScreen ? (
              <div
                style={{
                  width: "120px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={onThoughtSubmit}
                  disabled={submitting}
                  fullWidth
                >
                  {!submitting ? (
                    "Post"
                  ) : (
                    <TypeIt loop={true}>Posting...</TypeIt>
                  )}
                </Button>
              </div>
            ) : (
              <Button
                variant="contained"
                onClick={onThoughtSubmit}
                disabled={submitting}
                fullWidth
                style={{ marginBottom: "16px" }}
              >
                {!submitting ? "Post" : <TypeIt loop={true}>Posting...</TypeIt>}
              </Button>
            )}
          </Grid>
          <Grid size={{ xs: 12, sm: 9 }} style={{ marginBottom: "16px" }}>
            <Typography style={{ color: "#bdbdbd", fontSize: "12px" }}>
              Use markdown for formatting. CMD/Win + Enter to Post.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={successOpen}
        autoHideDuration={1000}
        message="Saved!"
        onClick={() => setSuccessOpen(false)}
        onClose={() => setSuccessOpen(false)}
      />
      <Toast
        message="Something went wrong"
        open={errored}
        onClose={() => setErrored(false)}
      />
    </Fragment>
  );
};
